import service from '@/services/procesos/SICUDbpm_own_task.js';

export default {
  namespaced: true,

  state: {
    config: {
      response: undefined
    },

    conditions: undefined,
  },

  mutations: {
    SET_RESPONSE(state, value) {
      state.config.response = value;
    }
  },

  actions: {
    async put({ dispatch, rootGetters, commit, rootState }) {
      const taskSelect = rootState.SIRbpm_own_task.tareas_en_edicion.find(
        element =>
          element.task__name === rootState.SIRbpm_own_task.tarea_en_seleccion
      );

      const currentCondition = {
        con_rej: false,
        con_usu: rootState.SIRbpm_dats_proc.operador_dato_en_seleccion,
        con_val: rootState.SIRbpm_dats_proc.valor_dato_en_seleccion,
        proc__dats: rootState.SIRbpm_dats_proc.dato_en_seleccion,
        proc__name: rootState.SIRbpm_proc.proceso_en_seleccion,
        task_nam_fin: rootState.SIRbpm_own_task.tarea_de_condicion,
        task_nam_ini: rootState.SIRbpm_own_task.tarea_en_seleccion
      };

      if (taskSelect.eebpm_con) {
        const isConditionClok = taskSelect.eebpm_con.some((condition) => condition.con_rej);

        if (isConditionClok && taskSelect.eebpm_con.length === 1) {
          taskSelect.eebpm_con.push(currentCondition);
        } else {
          const index = taskSelect.eebpm_con.findIndex((condition) => condition.con_rej === false);

          taskSelect.eebpm_con[index] = currentCondition;
        }
      } else {
          taskSelect.eebpm_con = [];
          taskSelect.eebpm_con.push(currentCondition);
      }

      const payload = {
        dsbpm_own_task: {
          eeDatos: [rootGetters['auth/getAuthData']],
          eebpm_own_task: [taskSelect]
        }
      };

      commit('SET_RESPONSE', false);
      await service
        .put(payload)
        .then(response => {
          if (response.data.dsbpm_own_task.eeEstados[0].Returnid != 0) {
            dispatch(
              'messaging/setMessage',
              response.data.dsbpm_own_task.eeEstados[0].Estado,
              { root: true }
            );
            commit('SET_RESPONSE', true);
          } else {
            dispatch('establecerDrawerRight', false, { root: true });
            commit('SET_RESPONSE', false);
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });
          commit('SET_RESPONSE', false);
        });
    }
  }
};
