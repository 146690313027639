<template>
  <v-container v-if="tareaEnSeleccion" fluid>
    <v-toolbar class="mb-3" :color="colors.primary" dark dense flat>
      <v-toolbar-title class="text-subtitle-1">{{
        $store.state.SIRbpm_dats_proc.labels.titulo
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <base-button
        :icon-key="close.key"
        :icon-text="close.text"
        :icon-value="close.value"
        @click="closeDrawer($event)"
      ></base-button>
    </v-toolbar>
    <v-row class="mt-6">
      <v-col cols="12">
        <input-select
          class="text-body-2"
          :color="colors.primary"
          :item-color="colors.primary"
          :items="datosDelProceso"
          :label="$store.state.SIRbpm_dats_proc.labels.condicion"
          outlined
          :value="$store.state.SIRbpm_dats_proc.dato_en_seleccion"
          @change="establecerDatoEnSeleccion($event)"
        />
      </v-col>
      <v-col cols="12">
        <input-select
          class="text-body-2"
          :color="colors.primary"
          :item-color="colors.primary"
          :disabled="$store.state.SIRbpm_dats_proc.estado_condiciones.operador"
          :items="operadoresDatoEnSeleccion"
          :label="$store.state.SIRbpm_dats_proc.labels.operador"
          outlined
          :value="$store.state.SIRbpm_dats_proc.operador_dato_en_seleccion"
          @change="establecerOperadorDatoEnSeleccion($event)"
        />
      </v-col>
      <v-col cols="12">
        <input-text
          class="text-body-2"
          :color="colors.primary"
          :disabled="$store.state.SIRbpm_dats_proc.estado_condiciones.valor"
          :label="$store.state.SIRbpm_dats_proc.labels.valor"
          :value="$store.state.SIRbpm_dats_proc.valor_dato_en_seleccion"
          @change="establecerValorDatoEnSeleccion($event)"
        />
      </v-col>
    </v-row>
    <v-divider class="mt-6" />
    <v-row class="mt-3 mb-6">
      <v-col cols="12">
        <input-select
          class="text-body-2"
          :color="colors.primary"
          :item-color="colors.primary"
          :disabled="$store.state.SIRbpm_dats_proc.estado_condiciones.tarea"
          :items="tareasParaCondicion"
          :label="$store.state.SIRbpm_dats_proc.labels.tarea"
          outlined
          :value="$store.state.SIRbpm_own_task.tarea_de_condicion"
          @change="establecerTareaDeCondicion($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        class="mt-3"
        :color="colors.primary"
        block
        dark
        x-large
        @click="guardarTarea()"
      >
        <span class="text-subtitle-1 text-capitalize">
          {{ $store.state.SIRbpm_dats_proc.labels.boton }}
        </span>
      </v-btn>
    </v-row>
    <base-snackbar
      :color="colors.primary"
      :enabled="snackbar.enabled"
      :text="snackbar.text"
      @close="closeSnackbar"
    ></base-snackbar>
  </v-container>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseSnackbar from '@/components/Base/BaseSnackbar.vue';
import colors from '@/styles/colors.js';
import InputSelect from '@/components/Base/InputSelect.vue';
import InputText from '@/components/Base/InputText.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'FormConditionView',

  components: {
    BaseButton,
    BaseSnackbar,
    InputSelect,
    InputText,
  },

  data() {
    return {
      colors: undefined,
      close: {},
      snackbar: {
        enabled: false,
        text: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      datosDelProceso: 'SIRbpm_dats_proc/datosDelProceso',
      icons: 'icons/getIcons',
      operadoresDatoEnSeleccion: 'SIRbpm_dats_proc/operadoresDatoEnSeleccion',
      tareasParaCondicion: 'SIRbpm_own_task/tareasParaCondicion',
    }),

    tareaEnSeleccion() {
      if (this.$store.state.SIRbpm_own_task.tarea_en_seleccion) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    this.colors = colors;
    this.close = this.obtenerIcono('close');
  },

  methods: {
    closeDrawer() {
      this.$store.dispatch('establecerDrawerRight', false);
    },

    closeSnackbar(event) {
      this.snackbar.enabled = event;
      this.$store.dispatch('messaging/setMessage', '');
    },

    establecerDatoEnSeleccion(event) {
      this.$store.dispatch('SIRbpm_dats_proc/establecerDatoEnSeleccion', event);
      this.$store.dispatch('SIRbpm_dats_proc/obtenerOperadoresDatoEnSeleccion');
    },

    establecerOperadorDatoEnSeleccion(event) {
      this.$store.dispatch(
        'SIRbpm_dats_proc/establecerOperadorDatoEnSeleccion',
        event
      );
    },

    establecerTareaDeCondicion(event) {
      this.$store.dispatch('SIRbpm_own_task/establecerTareaDeCondicion', event);
    },

    establecerValorDatoEnSeleccion(event) {
      this.$store.dispatch(
        'SIRbpm_dats_proc/establecerValorDatoEnSeleccion',
        event
      );
    },

    async guardarTarea() {
      await this.$store.dispatch('SICUDbpm_own_task/put');

      if (this.$store.state.SICUDbpm_own_task.config.response) {
        this.snackbar.text = this.$store.state.messaging.message;
        this.snackbar.enabled = true;
      }
    },

    obtenerIcono(icono) {
      return this.icons.find((element) => element.key === icono);
    },
  },
};
</script>