import service from '@/services/procesos/SIRbpm_proc_grf.js';

export default {
  namespaced: true,

  state: {
    config: {
      state: false,
      icon: undefined
    },

    labels: {
      titulo: 'Sumario de Proceso'
    },

    process_definition: undefined,

    task_features: [
      'task_stk',
      'task_res',
      'task_cxh',
      'task_bin',
      'task_sha',
      'task_msg',
      'task_brel',
      'task_dxt'
    ],

    task_types: {
      1: 'Disponible',
      2: 'Compartida'
    }
  },

  mutations: {
    SET_ICON(state, value) {
      state.config.icon = value;
    },

    SET_STATE(state, value) {
      state.config.state = value;
    },

    SET_PROCESS_DEFINITION(state, value) {
      state.process_definition = value;
    }
  },

  actions: {
    async get({ dispatch, commit, rootGetters }, data) {
      const { picproc__name } = data;
      const { pilinc__dat = true } = data;

      commit('SET_PROCESS_DEFINITION', undefined);

      const payload = {
        dsSIRbpm_proc_grf: {
          eeDatos: [rootGetters['auth/getAuthData']],
          eeSIRbpm_proc: [
            {
              picproc__name,
              pilinc__dat
            }
          ]
        }
      };

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsSIRbpm_proc_grf.eeEstados[0].Returnid === 0) {
            commit(
              'SET_PROCESS_DEFINITION',
              response.data.dsSIRbpm_proc_grf.eebpm_proc[0]
            );
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsSIRbpm_proc_grf.eeEstados[0].Estado,
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });
        });
    },

    updateConfig({ commit }, data) {
      commit('SET_ICON', data.icon);
      commit('SET_STATE', data.state);
    }
  },

  getters: {
    getProcessDefinition(state) {
      return state.process_definition;
    },

    getTaskFeatures(state) {
      return state.task_features;
    },

    getTaskTypes(state) {
      return state.task_types;
    }
  }
};
