<template>
  <v-snackbar :color="color" text :value="enabled" @input="close">
    <span class="d-flex justify-center">{{ text }}</span>
  </v-snackbar>
</template>

<script>
/**
 * Snackbar base en Integrity.
 * @author César Peñalosa
 * @version 1.0
 */
export default {
  name: 'BaseSnackbarComponent',

  props: {
    color: {
      type: String,
      default: '',
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },

  methods: {
    close(event) {
      this.$emit('close', event);
    },
  },
};
</script>