const colors = {
  primary: '#d30019',
  // primary: '#80BD28',
  secondary: '#ffffff',
  accent: '#82B1FF',
  error: '#ff5656',
  info: '#5699ff',
  success: '#0ad27b',
  warning: '#ffa556'
};

export default colors;
