import service from '@/services/procesos/SIRbpm_opr.js';

export default {
  namespaced: true,

  actions: {
    async get({ dispatch, rootGetters }, data) {
      const { picdat__type } = data;
      const { picproc__name = '*' } = data;

      dispatch('SIRbpm_dats_proc/establecerOperadoresDatoEnSeleccion', [], {
        root: true
      });

      const payload = {
        dsSIRbpm_opr: {
          eeDatos: [rootGetters['auth/getAuthData']],
          SIRbpm_opr: [
            {
              picdat__type,
              picproc__name
            }
          ]
        }
      };

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsSIRbpm_opr.eeEstados[0].Returnid === 0) {
            dispatch(
              'SIRbpm_dats_proc/establecerOperadoresDatoEnSeleccion',
              response.data.dsSIRbpm_opr.eebpm_opr,
              { root: true }
            );
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsSIRbpm_opr.eeEstados[0].Estado,
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });
        });
    }
  }
};
