<template>
  <v-text-field
    :color="color"
    dense
    :disabled="disabled"
    hide-details
    :label="label"
    outlined
    :value="value"
    @change="emitChangeEvent"
  />
</template>

<script>
/**
 * Campo de entrada de texto o input tipo text en Integrity.
 * @author César Peñalosa
 * @version 1.0
 */
export default {
  name: 'InputTextComponent',

  props: {
    /**
     * Establece el color del elemento seleccionado.
     */
    color: {
      type: String,
      default: 'primary',
    },

    /**
     * Deshabilitar el campo de entrada de texto.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Establece la etiqueta del campo de entrada de texto.
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * Establece el valor del campo de entrada de texto.
     */
    value: {
      type: String,
      default: '',
    },
  },

  methods: {
    emitChangeEvent(event) {
      /**
       * Cuando se establece un valor en el campo de entrada de texto se emite el evento change.
       * @event change
       * @type string
       */
      this.$emit('change', event);
    },
  },
};
</script>