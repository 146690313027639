import service from '@/services/procesos/SIRbpm_cmd.js';

export default {
  namespaced: true,

  state: {
    diagramXML: undefined
  },

  mutations: {
    SET_DIAGRAM_XML(state, data) {
      state.diagramXML = data;
    }
  },

  actions: {
    async get({ commit, dispatch, rootGetters }, data) {
      const { picproc_name } = data;

      const payload = {
        dsSIRbpm_cmd: {
          eeDatos: [rootGetters['auth/getAuthData']],
          eeSIRbpm_cmd: [
            {
              picproc_name
            }
          ]
        }
      };

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsSIRbpm_cmd.eeEstados[0].Returnid === 0) {
            const decode = window.atob(
              response.data.dsSIRbpm_cmd.eebpm_cmd[0].cmd_xml
            );

            commit('SET_DIAGRAM_XML', decode);
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsSIRbpm_cmd.eeEstados[0].Estado,
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });
        });
    }
  }
};
