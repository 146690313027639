import service from '@/services/procesos/SIRbpm_dats_proc.js';

export default {
  namespaced: true,

  state: {
    config: {
      response: undefined,
      state: false
    },

    dato_en_seleccion: undefined,
    datos_del_proceso: [],

    estado_condiciones: {
      operador: true,
      valor: true,
      tarea: true
    },

    labels: {
      boton: 'Guardar',
      condicion: 'Dato',
      operador: 'Operador',
      tarea: 'Si se cumple, saltar a:',
      titulo: 'Condición',
      valor: 'Valor'
    },

    operadores_dato_en_seleccion: [],
    operador_dato_en_seleccion: undefined,

    valor_dato_en_seleccion: undefined
  },

  mutations: {
    ESTABLECER_DATOS_DEL_PROCESO(state, value) {
      state.datos_del_proceso = value;
    },

    ESTABLECER_DATO_EN_SELECCION(state, value) {
      state.dato_en_seleccion = value;
    },

    ESTABLECER_ESTADO_CONDICIONES(state, value) {
      state.estado_condiciones = value;
    },

    ESTABLECER_OPERADOR_DATO_EN_SELECCION(state, value) {
      state.operador_dato_en_seleccion = value;
    },

    ESTABLECER_OPERADORES_DATO_EN_SELECCION(state, value) {
      state.operadores_dato_en_seleccion = value;
    },

    ESTABLECER_VALOR_DATO_EN_SELECCION(state, value) {
      state.valor_dato_en_seleccion = value;
    },

    SET_RESPONSE(state, value) {
      state.config.response = value;
    },

    SET_STATE(state, value) {
      state.config.state = value;
    }
  },

  actions: {
    establecerDatoEnSeleccion({ dispatch, commit }, value) {
      commit('ESTABLECER_DATO_EN_SELECCION', value);

      dispatch(
        'SIRbpm_dats_proc/establecerOperadorDatoEnSeleccion',
        undefined,
        { root: true }
      );

      dispatch('SIRbpm_dats_proc/establecerValorDatoEnSeleccion', undefined, {
        root: true
      });

      dispatch('SIRbpm_own_task/establecerTareaDeCondicion', undefined, {
        root: true
      });

      dispatch(
        'SIRbpm_dats_proc/establecerEstadoCondiciones',
        {
          operador: false,
          valor: true,
          tarea: true
        },
        { root: true }
      );
    },

    establecerEstadoCondiciones({ commit }, value) {
      commit('ESTABLECER_ESTADO_CONDICIONES', value);
    },

    establecerOperadorDatoEnSeleccion({ dispatch, commit }, value) {
      commit('ESTABLECER_OPERADOR_DATO_EN_SELECCION', value);

      dispatch('SIRbpm_dats_proc/establecerValorDatoEnSeleccion', undefined, {
        root: true
      });

      dispatch('SIRbpm_own_task/establecerTareaDeCondicion', undefined, {
        root: true
      });

      dispatch(
        'SIRbpm_dats_proc/establecerEstadoCondiciones',
        {
          operador: false,
          valor: false,
          tarea: true
        },
        { root: true }
      );
    },

    establecerOperadoresDatoEnSeleccion({ commit }, value) {
      commit('ESTABLECER_OPERADORES_DATO_EN_SELECCION', value);
    },

    establecerValorDatoEnSeleccion({ dispatch, commit }, value) {
      commit('ESTABLECER_VALOR_DATO_EN_SELECCION', value);

      dispatch('SIRbpm_own_task/establecerTareaDeCondicion', undefined, {
        root: true
      });

      dispatch(
        'SIRbpm_dats_proc/establecerEstadoCondiciones',
        {
          operador: false,
          valor: false,
          tarea: false
        },
        { root: true }
      );
    },

    async get({ dispatch, commit, rootGetters }, data) {
      const { picproc__name } = data;
      const { picproc__dats = '*' } = data;
      const { pictdat__user = '*' } = data;

      commit('ESTABLECER_DATOS_DEL_PROCESO', []);

      const payload = {
        dsSIRbpm_dats_proc: {
          eeDatos: [rootGetters['auth/getAuthData']],
          eeSIRbpm_dats_proc: [
            {
              picproc__dats,
              picproc__name,
              pictdat__user
            }
          ]
        }
      };

      commit('SET_RESPONSE', false);

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsSIRbpm_dats_proc.eeEstados[0].Returnid === 0) {
            commit(
              'ESTABLECER_DATOS_DEL_PROCESO',
              response.data.dsSIRbpm_dats_proc.eebpm_dats_proc
            );

            commit('SET_RESPONSE', false);
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsSIRbpm_dats_proc.eeEstados[0].Estado,
              { root: true }
            );

            commit('SET_RESPONSE', true);
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });

          commit('SET_RESPONSE', true);
        });
    },

    obtenerOperadoresDatoEnSeleccion({ dispatch, state }) {
      const datoEnSeleccion = state.datos_del_proceso.find(
        element => element.proc__dats === state.dato_en_seleccion
      );

      dispatch(
        'SIRbpm_opr/get',
        { picdat__type: datoEnSeleccion.tdat__user },
        { root: true }
      );
    }
  },

  getters: {
    datosDelProceso: state => {
      let items = [];
      state.datos_del_proceso.forEach(element => {
        items.push({
          disabled: false,
          text: element.dats_label,
          value: element.proc__dats
        });
      });
      return items;
    },

    operadoresDatoEnSeleccion: state => {
      let items = [];
      state.operadores_dato_en_seleccion.forEach(element => {
        items.push({
          disabled: !element.opr_apl,
          text: element.opr_type,
          value: element.opr_type
        });
      });
      return items;
    }
  }
};
