import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'xs',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#CF0021',
        accent: '#82B1FF',
        error: '#dc3545',
        info: '#17a2b8',
        success: '#28a745',
        warning: '#ffc107',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
