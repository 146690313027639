import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/microflujos/:jsonparams',
    name: 'Microflujos',
    component: () =>
      import(/* webpackChunkName: "microflujos" */ '../views/Microflujos.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
