<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="disabled" icon plain @click="emitClickEvent(iconKey)">
        <v-icon v-bind="attrs" v-on="on">
          {{ iconValue }}
        </v-icon>
      </v-btn>
    </template>
    <span>
      {{ iconText }}
    </span>
  </v-tooltip>
</template>

<script>
/**
 * Botón base en Integrity.
 * @author César Peñalosa
 * @version 1.0
 */
export default {
  name: 'BaseButtonComponent',

  props: {
    /**
     * Elimina la capacidad de hacer clic o apuntar al componente.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Identificador que representa de manera unívoca el icono desde la *biblioteca de iconos Integrity*.
     */
    iconKey: {
      type: String,
      required: true,
    },

    /**
     * Texto que aparece al pasar el mouse sobre el botón.
     */
    iconText: {
      type: String,
      default: '',
    },

    /**
     * Identificador que representa de manera unívoca el icono desde la biblioteca [Material Design Icons](https://materialdesignicons.com/)
     */
    iconValue: {
      type: String,
      required: true,
    },
  },

  methods: {
    emitClickEvent(event) {
      /**
       * Al hacer Click en el botón se emite este evento, como payload está el identificador que representa de manera unívoca el icono desde la *biblioteca de iconos Integrity*.
       * @event click
       * @type {object}
       * @property {object}
       */
      this.$emit('click', event);
    },
  },
};
</script>