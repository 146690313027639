import service from '@/services/procesos/SIRbpm_proc.js';

export default {
  namespaced: true,

  state: {
    labels: {
      titulo: 'Microflujo en Edición'
    },

    proceso_en_seleccion: undefined,
    procesos_en_edicion: []
  },

  mutations: {
    ESTABLECER_PROCESO_EN_SELECCION(state, value) {
      state.proceso_en_seleccion = value;
    },

    ESTABLECER_PROCESOS_EN_EDICION(state, value) {
      state.procesos_en_edicion = value;
    }
  },

  actions: {
    establecerProcesoEnSeleccion({ commit }, value) {
      commit('ESTABLECER_PROCESO_EN_SELECCION', value);
    },

    async get({ dispatch, commit, rootGetters }, data) {
      const { piccia__nit = '*' } = data;
      const { picproc__name = '*' } = data;
      const { piiproc_id = 2 } = data;

      const payload = {
        dsSIRbpm_proc: {
          eeDatos: [rootGetters['auth/getAuthData']],
          eeSIRbpm_proc: [
            {
              piccia__nit,
              picproc__name,
              picusuario: rootGetters['auth/getAuthData'].picusrcod,
              piiproc_id
            }
          ]
        }
      };

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsSIRbpm_proc.eeEstados[0].Returnid === 0) {
            commit(
              'ESTABLECER_PROCESOS_EN_EDICION',
              response.data.dsSIRbpm_proc.eebpm_proc
            );
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsSIRbpm_proc.eeEstados[0].Estado,
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });

          commit('ESTABLECER_PROCESOS_EN_EDICION', []);
        });
      dispatch('establecerProcesoEnSeleccion', undefined);
    }
  }
};
