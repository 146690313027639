import service from '@/services/procesos/SIRbpm_own_task.js';

export default {
  namespaced: true,

  state: {
    labels: {
      titulo: 'Step'
    },

    tarea_de_condicion: undefined,
    tareas_para_condicion: [],

    tarea_en_seleccion: undefined,
    tareas_en_edicion: []
  },

  mutations: {
    ESTABLECER_TAREA_DE_CONDICION(state, value) {
      state.tarea_de_condicion = value;
    },

    ESTABLECER_TAREAS_PARA_CONDICION(state, value) {
      state.tareas_para_condicion = value;
    },

    ESTABLECER_TAREA_EN_SELECCION(state, value) {
      state.tarea_en_seleccion = value;
    },

    ESTABLECER_TAREAS_EN_EDICION(state, value) {
      state.tareas_en_edicion = value;
    }
  },

  actions: {
    establecerTareaDeCondicion({ commit }, value) {
      commit('ESTABLECER_TAREA_DE_CONDICION', value);
    },

    establecerTareasParaCondicion({ commit }, value) {
      commit('ESTABLECER_TAREAS_PARA_CONDICION', value);
    },

    establecerTareaEnSeleccion({ dispatch, commit, state }, value) {
      commit('ESTABLECER_TAREA_EN_SELECCION', value);

      dispatch('SIRbpm_dats_proc/establecerDatoEnSeleccion', undefined, {
        root: true
      });

      /* dispatch(
        'SIRbpm_dats_proc/establecerOperadorDatoEnSeleccion',
        undefined,
        { root: true }
      ); */

      dispatch(
        'SIRbpm_dats_proc/establecerEstadoCondiciones',
        {
          operador: true,
          valor: true,
          tarea: true
        },
        { root: true }
      );

      if (value) {
        const tarea = state.tareas_en_edicion.find(
          element => element.task__name === value
        );

        if (tarea.eebpm_con) {
          const condicion = tarea.eebpm_con.find(
            element => element.con_rej === false
          );

          if (condicion) {
            dispatch(
              'SIRbpm_dats_proc/establecerDatoEnSeleccion',
              condicion.proc__dats,
              { root: true }
            );

            dispatch(
              'SIRbpm_dats_proc/obtenerOperadoresDatoEnSeleccion',
              undefined,
              {
                root: true
              }
            );

            dispatch(
              'SIRbpm_dats_proc/establecerOperadorDatoEnSeleccion',
              condicion.con_usu,
              { root: true }
            );

            dispatch(
              'SIRbpm_dats_proc/establecerValorDatoEnSeleccion',
              condicion.con_val,
              { root: true }
            );

            dispatch(
              'SIRbpm_own_task/establecerTareaDeCondicion',
              condicion.task_nam_fin,
              { root: true }
            );

            dispatch(
              'SIRbpm_dats_proc/establecerEstadoCondiciones',
              {
                operador: false,
                valor: false,
                tarea: false
              },
              { root: true }
            );
          }
        }
      }
    },

    async get({ dispatch, commit, rootGetters }, data) {
      const { piccia__nit = '*' } = data;
      const { picproc__name } = data;
      const { pictask__name } = data;
      const { piipos_lego = 0 } = data;
      const { piitask_fin } = data;

      dispatch('establecerTareasParaCondicion', []);

      const payload = {
        dsbpm_own_task: {
          eeDatos: [rootGetters['auth/getAuthData']],
          eeSIRbpm_own_task: [
            {
              piccia__nit,
              picproc__name,
              pictask__name,
              picusr__cod: rootGetters['auth/getAuthData'].picusrcod,
              picusuario: rootGetters['auth/getAuthData'].picusrcod,
              piipos_lego,
              piitask_fin
            }
          ]
        }
      };

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsbpm_own_task.eeEstados[0].Returnid === 0) {
            if (data.piitask_fin === 0) {
              commit(
                'ESTABLECER_TAREAS_EN_EDICION',
                response.data.dsbpm_own_task.eebpm_own_task
              );
            } else {
              dispatch(
                'establecerTareasParaCondicion',
                response.data.dsbpm_own_task.eebpm_own_task
              );
            }
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsbpm_own_task.eeEstados[0].Estado,
              { root: true }
            );
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });

          commit('ESTABLECER_TAREAS_EN_EDICION', []);
        });
    }
  },

  getters: {
    numeroDeTareasEnEdicion: state => {
      return state.tareas_en_edicion.length;
    },

    tareasParaCondicion: state => {
      let items = [];
      state.tareas_para_condicion.forEach(element => {
        items.push({
          disabled: false,
          text: element.task__label,
          value: element.task__name
        });
      });
      return items;
    }
  }
};
