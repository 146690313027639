import service from '@/services/procesos/SICUDdeployMF.js';

export default {
  namespaced: true,

  state: {
    config: {
      response: undefined,
      state: false
    },

    labels: {
      text: '¿Desea publicar el microflujo?',
      title: 'Advertencia'
    }
  },

  mutations: {
    SET_RESPONSE(state, value) {
      state.config.response = value;
    },

    SET_STATE(state, value) {
      state.config.state = value;
    }
  },

  actions: {
    async get({ dispatch, commit, rootGetters }, data) {
      const { proc_name } = data;

      const payload = {
        dsDeployMF: {
          eeDatos: [rootGetters['auth/getAuthData']],
          ttdeploymf: [
            {
              proc_name
            }
          ]
        }
      };

      commit('SET_RESPONSE', false);

      await service
        .get(payload)
        .then(response => {
          if (response.data.dsDeployMF.eeEstados[0].Returnid === 0) {
            commit('SET_RESPONSE', true);
          } else {
            dispatch(
              'messaging/setMessage',
              response.data.dsDeployMF.eeEstados[0].Estado,
              { root: true }
            );

            commit('SET_RESPONSE', false);
          }
        })
        .catch(error => {
          dispatch('messaging/setMessage', error.toString(), {
            root: true
          });

          commit('SET_RESPONSE', false);
        });
    }
  }
};
