import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.js';
import icons from './icons.js';
import messaging from './messaging.js';
import SICUDbpm_own_task from './procesos/SICUDbpm_own_task.js';
import SICUDdeployMF from './procesos/SICUDdeployMF.js';
import SIRbpm_cmd from './procesos/SIRbpm_cmd.js';
import SIRbpm_dats_proc from './procesos/SIRbpm_dats_proc.js';
import SIRbpm_opr from './procesos/SIRbpm_opr.js';
import SIRbpm_own_task from './procesos/SIRbpm_own_task.js';
import SIRbpm_proc from './procesos/SIRbpm_proc.js';
import SIRbpm_proc_grf from './procesos/SIRbpm_proc_grf.js';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    auth,
    icons,
    messaging,
    SICUDbpm_own_task,
    SICUDdeployMF,
    SIRbpm_cmd,
    SIRbpm_dats_proc,
    SIRbpm_opr,
    SIRbpm_own_task,
    SIRbpm_proc,
    SIRbpm_proc_grf
  },

  state: {
    currentBlockTitle: undefined,
    drawer_right: false,
    grid: false,
    title: undefined
  },

  mutations: {
    ESTABLECER_DRAWER_RIGHT(state, value) {
      state.drawer_right = value;
    },

    SET_CURRENT_BLOCK_TITLE(state, value) {
      state.currentBlockTitle = value;
    },

    SET_GRID(state, value) {
      state.grid = value;
    },

    SET_TITLE(state, value) {
      state.title = value;
    }
  },

  actions: {
    establecerDrawerRight({ commit }, value) {
      commit('ESTABLECER_DRAWER_RIGHT', value);
    },

    toggleGrid({ commit }, flag) {
      commit('SET_GRID', flag);
    },

    toggleTitle({ commit, state }, data) {
      if (data.type === 'block') {
        if (data.currentBlockTitle != undefined) {
          commit('SET_TITLE', `Tareas ${data.text}`);
          commit('SET_CURRENT_BLOCK_TITLE', `Tareas ${data.currentBlockTitle}`);
        } else {
          commit('SET_TITLE', state.currentBlockTitle);
        }
      } else {
        commit('SET_TITLE', state.SIRbpm_proc_grf.labels.titulo);
      }
    }
  }
});
