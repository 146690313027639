export default {
  namespaced: true,

  state: {
    icons: [
      {
        key: 'close',
        text: 'Cerrar',
        value: 'mdi-close',
      },
      {
        key: 'task_stk',
        text: 'Subtarea',
        value: 'mdi-file-tree',
      },
      {
        key: 'task_res',
        text: 'Reasignar tarea',
        value: 'mdi-account-switch',
      },
      {
        key: 'task_cxh',
        text: 'Lista de cosas por hacer',
        value: 'mdi-format-list-bulleted-type',
      },
      {
        key: 'task_brel',
        text: 'Recibe bitácora inicial',
        value: 'mdi-comment-text-multiple',
      },
      {
        key: 'task_msg',
        text: 'Comparte bitácora de la instancia',
        value: 'mdi-forum',
      },
      {
        key: 'task_bin',
        text: 'Enviar mensajes a otras bitácoras',
        value: 'mdi-message',
      },
      {
        key: 'task_sha',
        text: 'Recibir mensajes de otras bitácoras',
        value: 'mdi-send',
      },
      {
        key: 'task_dxt',
        text: 'Condición',
        value: 'mdi-rhombus',
      },
      {
        key: 'expand',
        text: '',
        value: 'mdi-unfold-more-horizontal',
      },
      {
        key: 'shrink',
        text: '',
        value: 'mdi-unfold-less-horizontal',
      },
      {
        key: 'monitor-eye',
        text: 'Abrir sumario del proceso',
        value: 'mdi-monitor-eye',
      },
      {
        key: 'monitor-off',
        text: 'Cerrar sumario del proceso',
        value: 'mdi-monitor-off',
      },
    ],
  },

  getters: {
    getIcons(state) {
      return state.icons;
    },
  },
};
