export default {
  namespaced: true,

  state: {
    local_ip: undefined,
    picfiid: undefined,
    picusrcod: undefined,
    remote_ip: undefined
  },

  mutations: {
    ESTABLECER_PICFIID(state, payload) {
      state.picfiid = payload;
    },
    
    ESTABLECER_PICUSRCOD(state, payload) {
      state.picusrcod = payload;
    }
  },

  actions: {
    setAuth({ commit }, data) {
      commit('ESTABLECER_PICFIID', data.picfiid);
      commit('ESTABLECER_PICUSRCOD', data.picusrcod);
    }
  },

  getters: {
    getAuthData(state) {
      return {
        picusrcod: state.picusrcod,
        picfiid: state.picfiid,
        local_ip: state.local_ip,
        remote_ip: state.remote_ip
      };
    }
  }
};
