<template>
  <v-select
    dense
    :color="color"
    :disabled="disabled"
    hide-details
    :item-color="itemColor"
    :items="items"
    :label="label"
    outlined
    :value="value"
    @change="emitChangeEvent"
  />
</template>

<script>
/**
 * Campo menú de opciones o select en Integrity.
 * @author César Peñalosa
 * @version 1.0
 */
export default {
  name: 'InputSelect',

  props: {
    /**
     * Establece el color del elemento seleccionado.
     */
    color: {
      type: String,
      default: 'primary',
    },

    /**
     * Permite desactivar la interacción con el componente.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Establece el color del elemento seleccionado.
     */
    itemColor: {
      type: String,
      default: 'primary',
    },

    /**
     * Arreglo de objetos que representa los elementos que contiene el campo select.
     */
    items: {
      type: Array,
      required: true,
    },

    /**
     * Establece la etiqueta del campo select.
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * Establece el valor del campo select.
     */
    value: {
      type: String,
      default: '',
    },
  },

  methods: {
    emitChangeEvent(event) {
      /**
       * Cuando se establece un valor en el campo select se emite el evento change.
       * @event change
       * @type string
       */
      this.$emit('change', event);
    },
  },
};
</script>