export default {
  namespaced: true,

  state: {
    message: ''
  },

  mutations: {
    SET_MESSAGE(state, value) {
      state.message = value;
    }
  },

  actions: {
    setMessage({ commit }, value) {
      commit('SET_MESSAGE', value);
    }
  },

  getters: {
    getMessage(state) {
      return state.message;
    }
  }
};
