<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-navigation-drawer
      app
      right
      temporary
      :value="$store.state.drawer_right"
      width="650"
      @input="closeDrawer($event)"
    >
      <form-condition></form-condition>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import FormCondition from "@/views/FormCondition.vue";

export default {
  name: "AppView",

  components: {
    FormCondition,
  },

  methods: {
    closeDrawer(event) {
      if (event === false) {
        this.$store.dispatch("establecerDrawerRight", false);
      }
    },
  },
};
</script>